/* Variables
   ========================================================================== */

// Colors
$rise-green: #4ab767;
// $rise-blue: #0081BA;
$start-color: #1f71a2;
$stop-color: #045888;
$danger: #d9534f;
$google-red: #df4a32;
$rise-premium: #dbf0e0;
$rise-blue: #107dda;

// Tones
$white: #fff;
$lighter-gray: #f2f2f2;
$light-gray: #e6e6e6;
$mid-gray: #999999;
$gray: #555;
$dark-gray: #4d4d4d;

$border-color: #ccc;
$light-border-color: #DDDDDD;
$blue-border-color: $rise-blue;
$border: 1px solid $border-color;

// Spacing
$title-margin: 6px;

// New Style
$madero-black: #020620;
$madero-gray: #999999;
$madero-light-blue: $rise-blue;
$madero-green: #45b764;
$madero-green-active: #10792D;
$maderio-green-disabled: #a2dbb2;

// Text
$default-text: $madero-black;
$text-inverse: $white;

// Padding
$pad-sm: .25rem; //4px
$pad-md: .5rem; //8px
$pad-lg: 1rem; //16px

// Shadows
$bottom-shadow: 0 .25rem .25rem $madero-gray;
$bottom-shadow-small: 0 0 2px $madero-gray;
$vignette-shadow: inset 0 0 3px $madero-gray;

// Template Cards
$card-border-radius: 1rem;
$card-image-radius: .5rem;
$card-color: $white;
$card-margin: $pad-md;

//Transitions
$transition-default: .25s ease all;
