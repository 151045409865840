.display-fields-body {

  @media (min-width: $screen-md) {
    height: calc(100vh - 296px - var(--common-header-height)) !important;
  }

  .scrollable-list {
      height: 100%;

    .list-group-item {
      @extend .u_padding-md;
    }

    .control-label {
      width: 241px;

      @media (min-width: $screen-md) {
        min-width: 241px;
      }
    }
  }

  .btn-group-justified {
    @extend .mb-0;

    width: 164px;

    button {
      width: 80px;
      font-weight: normal;
    }

    streamline-icon {
      @extend .ml-3;
    }
  }

  .form-inline {
    .btn-schedule-selector {
      span {
        @media (min-width: $screen-md) {
          max-width: 170px;
        }
      }
    }
  }

  .btn-group-checkbox {
    position: relative;

    input[type=checkbox] {
      position: absolute;
      cursor: pointer;
      left: 0;
      top: 0;
      width: 167px;
      height: 40px;
      margin: 0;
      padding: 0;
      vertical-align: top;
      z-index: 1;
      opacity: 0;
    }
  }

  .display-screenshot-panel {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .display-screenshot {
      max-width: 100%;
      aspect-ratio: 16 / 9;
      background: $light-gray;

      .display-screenshot-info {
        @extend .text-center;
        @extend %vertical-align;
      }
    }
  }

  .img-screenshot {
    max-width: 100%;
    object-fit: contain;
  }

  .display-screenshot-panel, .img-screenshot {
    width: 100%;

    @media (min-width: $screen-md) {
      width: auto;
      height: calc(100vh - 538px - var(--common-header-height));
      min-height: 240px;
    }

    @media (min-width: $screen-lg) {
      height: calc(100vh - 518px - var(--common-header-height));
    }
  }

  .address-fields .form-group {
    @extend .mb-3;
  }

}

.btn-schedule-selector {
  span {
    display: inline-block;
    vertical-align: middle;

    &:first-child {
      width: calc(100% - 13px);
    }
  }
}

.highlight-text {
  font-family: 'Lucida Console', Monaco, monospace;
  background: $light-gray;
}

.display-instructions-panel .close {
  width: auto;
  height: auto;
}

preview-selector {
  width: calc(100% - 40px);
}

/* Deprecate Below
 +   ==========================================================================
 +   ==========================================================================
 +   ==========================================================================
 +   ==========================================================================
 +   ========================================================================== */

.display-name {
  span {
    @extend .u_ellipsis-md;
    max-width: 240px;
    display: inline-block;
    vertical-align: middle;
  }
}

.display-address {
  span {
    @extend .u_ellipsis-md;
    max-width: 320px;
    display: inline-block;
    vertical-align: middle;
  }
}

.offline {
  &:before {
    color: #e74c3c;
  }
}

.notinstalled {
  &:before {
    color: #444;
  }
}

.online {
  &:before {
    color: $rise-green;
  }
}
